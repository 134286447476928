<template>
  <div class="donation-widget-component" :class="{ opened: isTopWidgetActive }" >
    <div class="donation-header">
      <slot name="header" :topWidgetHandler="topWidgetHandler" />

      <div class="frequency-btns-container">
        <button
          type="button"
          class="donation-widget-btns -frequency rounded-r-none"
          :class="{ active: interval == 'once' }"
          @click="interval = 'once'"
          id="donate-widget-frequency-oneoff"
        >
          <static-string>One-off</static-string>
        </button>

        <button
          type="button"
          class="donation-widget-btns -frequency rounded-l-none"
          :class="{ active: interval == 'monthly' }"
          @click="interval = 'monthly'"
          id="donate-widget-frequency-monthly"
          >
          <static-string> Monthly </static-string>
        </button>
      </div>
    </div>

    <div class="donate-main-wrapper">
      <div class="donation-widget-main-container" v-if="interval == 'once'">
        <PricePill
          :price="10"
          :isActive="!donation.isCustomAmount && donation.amount == 10"
          :isInvalid="invalid.amount"
          @select="selectRegularPrice('once', $event)"
        />

        <PricePill
          :price="20"
          :isActive="!donation.isCustomAmount && donation.amount == 20"
          :isInvalid="invalid.amount"
          @select="selectRegularPrice('once', $event)"
        />

        <PricePill
          :price="35"
          :isActive="!donation.isCustomAmount && donation.amount == 35"
          :isInvalid="invalid.amount"
          @select="selectRegularPrice('once', $event)"
        />

        <CustomPricePill
          :isActive="donation.isCustomAmount"
          :isInvalid="invalid.amount || invalid.customAmount"
          @select="selectCustomPrice('once', $event)"
        />
      </div>

      <div class="donation-widget-main-container" v-else>
        <PricePill
          :price="5"
          :isActive="!donation.isCustomAmount && donation.amount == 5"
          :isInvalid="invalid.amount"
          @select="selectRegularPrice('monthly', $event)"
        />

        <PricePill
          :price="10"
          :isActive="!donation.isCustomAmount && donation.amount == 10"
          :isInvalid="invalid.amount"
          @select="selectRegularPrice('monthly', $event)"
        />

        <PricePill
          :price="15"
          :isActive="!donation.isCustomAmount && donation.amount == 15"
          :isInvalid="invalid.amount"
          @select="selectRegularPrice('monthly', $event)"
        />

        <CustomPricePill
          :isActive="donation.isCustomAmount"
          :isInvalid="invalid.amount || invalid.customAmount"
          @select="selectCustomPrice('monthly', $event)"
        />

      
      </div>
      <div v-if="invalid.amount || invalid.customAmount" class="text-red">
        <static-string> Amount is invalid! It should be a whole number. </static-string>
      </div>

      <static-string class="hidden" ref="optionValue"> Use my donation for any campaign </static-string>

      <!-- TODO: style this -->
      <div class="donation-dropdown" :class="{ '-invalid': invalid.campaign }">
        <select ref="campaignSelect" v-model="campaign">
          <option value=""><static-string>Select your preferred campaign</static-string></option>
          <option v-for="campaign in campaigns" :key="campaign.id" :value="campaign">
            {{ campaign.title }}
          </option>
        </select>
        <div v-if="invalid.campaign" class="text-red">
          <static-string> You must select a campaign.</static-string>
        </div>
        <div v-if="invalid.amount" class="text-red">
          <static-string> You must select a amount.</static-string>
        </div>
      </div>

      <div class="mt-8">
        <button @click="onSubmit()" class="checkout-btn" type="button" id="donate-widget-checkout-btn">
          <static-string>Checkout</static-string> 
        </button>
      </div>
    </div>
    

    <DonateModal :donation="donation" ref="modal" class="donation-modal-component" />
  </div>
</template>

<script>
import EUR from '../../../images/icons/eu-logo.svg';
import HandleI18n from '../Utils/HandleI18n';
import PricePill from './PricePill.vue';
import CustomPricePill from './CustomPricePill.vue';
import DonateModal from '../Modal/DonateModal.vue';
import StaticString from '../../statics/StaticString.vue'

export default {
  mixins: [HandleI18n],
  inject: ['campaigns'],

  components: { CustomPricePill, DonateModal, PricePill, StaticString},

  watch: {
    donation: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('change', val);
      }
    }
  },

  data() {
    return {
      EUR,

      interval: 'once',
      // We store the two "forms" data independently to avoid conflicts
      amount: {
        once: {
          amount: 10,
          isCustomAmount: false
        },
        monthly: {
          amount: 5,
          isCustomAmount: false
        }
      },
      campaign: '',

      invalid: {
        amount: false,
        customAmount: false,
        campaign: false
      },

      isTopWidgetActive: false
    };
  },

  computed: {
    // Returns the current active form data
    // Use this for actual donations
    donation() {
      return {
        interval: this.interval,
        amount: this.amount[this.interval].amount,
        isCustomAmount: this.amount[this.interval].isCustomAmount,
        campaign: this.campaign
      };
    }
  },

  methods: {
    onSubmit() {
      if (!this.validate()) {
        return;
      }

      this.$refs.modal.open();
    },

    validateCustomAmount() {
      if (this.donation.isCustomAmount  && !Number.isInteger(this.donation.amount)){
        this.invalid.customAmount = true;
        return false;
      }
    },

    validate() {
      this.resetInvalidState();
      this.validateCustomAmount();

      if (!this.donation.amount) {
        if (this.donation.isCustomAmount) {
          this.invalid.customAmount = true;
        } else {
          this.invalid.amount = true;
        }
      }

      if (!this.donation.campaign) {
        this.invalid.campaign = true;
      }

      return !(this.invalid.amount || this.invalid.customAmount || this.invalid.campaign);
    },

    resetInvalidState() {
      this.invalid.amount = false;
      this.invalid.customAmount = false;
      this.invalid.campaign = false;
    },

    selectRegularPrice(interval, amount) {
      this.amount[interval].amount = amount;
      this.amount[interval].isCustomAmount = false;
    },

    selectCustomPrice(interval, amount) {
      this.amount[interval].amount = amount;
      this.amount[interval].isCustomAmount = true;
    },

    topWidgetHandler() {
      this.isTopWidgetActive = !this.isTopWidgetActive;
     

      if(this.isTopWidgetActive) {
        let widget = document.querySelector('.top-donation-widget'),
          windowYPosition = window.pageYOffset;

        widget.classList.remove('sticky');
        widget.classList.remove('top-14');

        widget.style.position = 'absolute';
        widget.style.top = windowYPosition + 'px';
        widget.style.index = 10;
      
        document.addEventListener('scroll', () => {
          if(window.pageYOffset > windowYPosition + widget.offsetHeight) {
            this.resetWidgetStyles(widget);
            this.isTopWidgetActive = false;
          };
        });
      }
      
      else {
        let widget = document.querySelector('.top-donation-widget');
        this.resetWidgetStyles(widget);
      }
    },

    resetWidgetStyles(widget) {
      widget.style.position = '';
      widget.style.top = '';
      widget.style.index = 9;

      widget.classList.add('sticky');
      widget.classList.add('top-14');
    }
  },
  
  mounted() {
    this.$refs.campaignSelect.children[1].innerText = this.$refs.optionValue.$el.innerText
  }
};
</script>
