<template>
  <!--
    Paypal documentation

    https://developer.paypal.com/api/nvp-soap/paypal-payments-standard/integration-guide/Appx-websitestandard-htmlvariables/#link-paymenttransactionvariables
  -->
  <form
    class="flex items-stretch w-full"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
    target="_top"
  >
    <input type="hidden" name="business" :value="paypalMerchantId" />
    <input type="hidden" name="cmd" value="_donations" />

    <!-- Amount should be in the following format: '10.00' -->
    <input type="hidden" name="amount" :value="amountFormattedForInput" />
    <input type="hidden" name="currency_code" value="EUR" />

    <!-- This enables the "Make your donation recurring" checkbox -->
    <input type="hidden" name="no_recurring" value="0" />

    <input type="hidden" name="return" :value="paypalSuccessPageUrl" />
    <!-- The buyer's browser is redirected to the return URL by using the POST method, and all payment variables are included. -->
    <input type="hidden" name="rm" value="2" />
    <input type="hidden" name="cancel_return" :value="paypalCancelPageUrl" />
    <input type="hidden" name="notify_url" :value="ipnWebhook"> 

    <button
      id="donate-page-btn-pp"
      type="submit"
      class="donation-buttons -payment bg-orange text-white w-full"
    >
      <static-string>Pay With PayPal</static-string>
    </button>

    <img
      border="0"
      src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
      width="1"
      height="1"
    />
  </form>
</template>

<script>
export default {
  inject: ['paypalMerchantId', 'paypalSuccessPageUrl', 'paypalCancelPageUrl', 'ipnWebhook'],

  props: {
    donation: { required: true, type: Object }
  },

  computed: {
    amountFormattedForInput() {
      return `${this.donation.amount}.00`;
    },

    amount() {
      return this.donation.amount;
    },

    isRecurring() {
      return this.donation.interval === 'monthly';
    }
  }
};
</script>

<style lang="scss" scoped>
.donation-buttons {
  min-height: 4rem;
}
</style>
